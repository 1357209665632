.active__fav_link {
  > span > svg {
    > path {
      stroke: #f5016b;
    }
  }
}
.fav_link {
  > span > svg {
    > path {
      stroke: #070707;
    }
  }
}
