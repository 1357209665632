.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
}

.cardList>a>li:hover {
    background: linear-gradient(90deg, #F1F1F1 0%, rgba(238, 246, 250, 0.19) 82.82%, rgba(237, 248, 252, 0) 107.25%);
}

.categoryIcon svg {
    height: 14px !important;
}

.menuOverflow {
    height: 100vh;
    overflow-y: scroll;

}

.search_icon {
    position: absolute;
    left: 10px;
}
.close_icon {
    svg {
        width: 25px;
        height: 25px;
    }
}
.input {
    box-sizing: border-box;
    padding: 0 20px 0 55px;
    width: 100%;
}

.search_img {
    object-fit: cover;
    width: 63px;
    height: 90px;
}

.highlight {
    font-weight: 700;
}
.category_search {
    display: block;
    padding: 10px 20px;
}
.category_search:active {
    color: rgb(245 1 107);
}
.category_search:hover {
    color: #F400A1;
}
.card_item {
    // Other styles...

    &:hover {
        .card_name {
            color: #F400A1;
        }
    }
}

@media (max-width: 960px) {
    .headLinks {
        display: none;
    }
}

.search_input {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 150;
}
.search_window {
    position: fixed;
    top: -100px;
    left: 0;
    right: 0;
    bottom: 100vh;
    background-color: white;
    z-index: 1;
    transition: all ease 0.3s;
    opacity: 0;
}
.search_result {
    top: 60px;
    height: calc(100vh - 60px);
    opacity: 100%;
    z-index: 99;
}
.search_window_open {
    bottom: 0;
}
.empty_title {
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    margin-bottom: 10px;
    padding: 0 20px;
}
.empty_text {
    font-size: 14px;
    font-weight: 400;
    line-height: 17.07px;
    padding: 0 20px;
}