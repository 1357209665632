.next {
  margin: 100px 0 50px;
  button {
    width: 100%;
  }
}
.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 50px 30px;
}
@keyframes shake {
  0%, 100% { transform: translateY(0); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% { transform: translateY(-2px); }
  10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% { transform: translateY(2px); }
}
.error {
  border-bottom: 1px solid #F400A1;
  animation: shake 1s ease;
  input {
    border: none;
  }

}