$base: #263238;
$lite: #fff;
$brand: #ff3d00;
$size: 48px;

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
  .loader {
    width: $size;
    height: $size;
    display: inline-block;
    position: relative;
    border: 3px solid $lite;
    border-radius: 50%;
    animation: animloader94 2s linear infinite;
    &::after {
      content: "";
      width: 6px;
      height: 24px;
      background: $lite;
      transform: rotate(-45deg);
      position: absolute;
      bottom: -20px;
      left: 46px;
    }
  }
}

@keyframes animloader94 {
  0% {
    transform: translate(-10px, -10px);
  }
  25% {
    transform: translate(-10px, 10px);
  }
  50% {
    transform: translate(10px, 10px);
  }
  75% {
    transform: translate(10px, -10px);
  }
  100% {
    transform: translate(-10px, -10px);
  }
}
