.submitBtn_primary {
  padding: 13px 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  background: #070707;
  height: 50px;
  &:disabled {
    color: #070707;
    background: rgba(217, 217, 217, 0.3);
  }
}
.submitBtn_secondary {
  padding: 13px 50px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  background: #070707;
  &:disabled {
    color: #070707;
    background: rgba(217, 217, 217, 0.3);
  }
}

@media screen and (max-width: 768px) {
  // .submitBtn_primary {
  //   font-size: 14px;
  //   font-weight: 400;
  // }
  .submitBtn_secondary {
    text-decoration-line: underline;
    background: transparent;
    padding: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #070707;
    &:disabled {
      background: transparent;
    }
  }
}
.submit_btn_secondary:hover {
  background-color: #F400A1;
}