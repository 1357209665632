@mixin scroll() {
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #CCCCCC;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #999999;
  }
  scrollbar-width: thin;
  scrollbar-color: #CCCCCC transparent;
}
.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  width: 260px;
  height: 40px;
  padding: 0 20px;
  cursor: default;
}
.item_mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  width: 100%;
  height: 20px;
}
.item_back_mobile {
  height: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  svg {
    transform: rotate(180deg);
  }
}
.item:hover p{
  font-weight: 500;
}

.list_items {
  @include scroll;
  height: 530px;
  position: absolute;
  top: 0;
  right: -260px;
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.list_items_mobile {
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: white;
  height: 90vh;
  width: 100vw;
  padding: 20px 20px 40px;
  z-index: 150;
  margin: 13px 0;
}
.container {
  overflow: visible;
}

@media screen and (max-width: 767px){
  .container {
    margin: 13px 0;
  }

}