.container {
  padding: 50px 0;
  margin: auto;
  width: 70vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 80vh;
}

.images_container {
  flex-grow: 1;
  position: relative;
  max-width: 650px;
}

.img1 {
  position: absolute;
  left: 30px;
}

.img3 {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(0, 0);
}

.img2 {
  position: absolute;
  right: 0;
}

.thick_text {
  font-size: 24px;
  font-weight: 600;
  line-height: 29.26px;
}

.text {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;

  a {
    text-decoration: underline;
  }
}
.button {
  display: none;
}

@media screen and (max-width: 767px) {
  .container {
    padding: 50px 0;
    margin: auto;
    width: 100%;
    display: block;
    height: 100%;
  }
  .images_container {
    position: relative;
    height: 40vh;
  }
  .img1 {
    position: absolute;
    left: 10vw;
    top: 10vh;
    width: 115px;
  }

  .img3 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 125.33px;
  }

  .img2 {
    position: absolute;
    right: 20vw;
    top: 5vh;
    width: 96px;
  }
  .thick_text {
    margin-left: 5vw;
  }
  .text {
    margin-left: 5vw;
  }
  .button {
    display: block;
    padding: 9px 15px;
    background-color: #070707;
    color: white;
    width: 90vw;
    margin: 0 auto;
    margin-top: 20px;
    text-align: center;
  }
}