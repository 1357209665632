.column {
  position: relative;
  display: flex;
  flex-direction: column;

}
.catalog_window {
  z-index: 20;
  position: fixed;
  left: 10vw;
  right: 10vw;
  top: 105px;
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: white;
  padding: 30px;
  height: 575px;
}

.background {
  background-color: #8C8C8C4D;
  position: fixed;
  left: 0;
  right: 0;
  top: 105px;
  bottom: 0;
  z-index: -10;
}