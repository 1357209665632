@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Montserrat", sans-serif;
}
@media screen and (min-width: 1220px) {
  .container {
    max-width: 1210px;
    box-sizing: border-box;
  }
}
.container {
  margin: 0 auto;
}

body {
  background: #f9f9f9;
  overflow-x: hidden;
}
ol,
ul {
  list-style-position: inside;
}
button:disabled {
  cursor: not-allowed;
}
/* Скрываем кнопку "крестик" в поле поиска */
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}
input:focus,
textarea:focus {
  outline: none;
}
.headerRightNav-link {
  path {
    transition: 0.3s;
  }
}
.headerRightNav-list_item-cart {
  path {
    transition: 0.3s;
  }
}
.headerRightNav-link:hover {
  path {
    stroke: #F400A1;
  }
}

.headerRightNav-list_item-cart:hover {
  path {
    fill: #F400A1;
  }
}

.arrowsBtn {
  &:disabled {
    > svg {
      > path {
        stroke: #9b9b9b;
      }
    }
  }
}
h2.page__title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  color: #070707;
}
.chars__item {
  .chars__term {
    position: relative;
    overflow: hidden;
    flex-grow: 1;
    &::after {
      content: "..................................................................................................................................";
      position: absolute;
    }
  }
}

.OrderHistoryInnerPage-btn {
  color: #fff !important;
  background: #070707 !important;
  padding: 13px 50px !important;
  text-decoration-line: none !important;
}

.ant-drawer-top > .ant-drawer-content-wrapper {
  top: 100px !important;
}

.ant-btn-primary {
  background-color: #070707 !important;
  &:hover {
    background-color: #F400A1 !important;
    box-shadow: 0 2px 0 #f5016bfa;
  }
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: #F400A1 !important;
  background-color: #F400A1 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #F400A1 !important;
  border-color: #F400A1 !important;
}

.ant-checkbox .ant-checkbox-inner {
  border-radius: 0px;
}
.ant-checkbox-group {
  flex-direction: column;
}
.ant-menu .ant-menu-item-selected {
  background-color: #070707 !important;
  color: #fff !important;
}

.ant-menu-item-active {
  background-color: #070707;
  color: #fff !important;
}
.ant-collapse-content-box {
  padding-left: 0 !important;
}

@media screen and (max-width: 768px) {
  .ant-menu .ant-menu-item-selected {
    background-color: transparent !important;
    color: #F400A1 !important;
  }

  .ant-menu-item-active {
    background-color: transparent;
    color: #F400A1 !important;
  }
  h2.page__title {
    font-size: 20px;
    line-height: 24px;
  }
}

.ant-menu-inline .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-item-group-list .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title:hover {
  background-color: #F400A1 !important;
  color: #fff !important;
}

.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  color: #fff !important;
}
.ant-picker {
  background: transparent !important;
}

.ant-btn-primary:disabled {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.ant-collapse-header {
  border-bottom: 1px solid #8c8c8c;
  padding: 20px 0 14px 0 !important;
  border-radius: 0 !important;
}

.productTitle {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Определяет максимальное количество строк для отображения */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  transition: all 0.3s ease;
}
.productTitle:hover {
  color: #E2A3CD;
}
.ant-breadcrumb {
  li {
    .ant-breadcrumb-link:hover {
      color: #F400A1;
      cursor: pointer;
    }
    &:last-child {
      .ant-breadcrumb-link:hover {
        color: rgba(0, 0, 0, 0.88);
        cursor: default;
      }
    }
  }
}
.ant-drawer-header-title {
  flex-direction: row-reverse;
}
.ant-modal .ant-modal-content {
  border-radius: 0px;
}

/* Chrome, Safari, Edge, Opera */
.otpInp::-webkit-outer-spin-button,
.otpInp::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.otpInp[type="number"] {
  -moz-appearance: textfield;
}

.ant-slider .ant-slider-handle {
  width: 20px;
  height: 20px;
  &::before {
    width: 24px;
    height: 24px;
  }
  &::after {
    width: 20px;
    height: 20px;
  }
  &:focus,
  &:hover,
  &:active {
    &::after {
      width: 20px;
      height: 20px;
    }
    &::before {
      width: 24px;
      height: 24px;
    }
  }
}
.ant-slider-horizontal .ant-slider-handle {
  inset-block-start: -5px;
}
.ant-slider .ant-slider-mark-text {
  margin: 15px 0;
  white-space: nowrap;
}
.ant-select .ant-select-arrow {
  inset-inline-end: 0px;
}
.productInnerCharacter {
   dt,dd {
    font-size: 18px !important;
  }
}

@media screen and (max-width: 768px) {
  .slick-track {
    //height: 400px;
  }
  .productInnerCharacter {
     dt,dd {
      font-size: 16px !important;
    }
  }
}

.ant-badge .ant-badge-count {
  background: #F400A1;
}

.message__added_to_cartt {
  color: red;
}
.ant-checkbox-wrapper {
  margin: 5px 0;
}
.ant-checkbox-input {
  width: 20px;
  height: 20px;
}
.ant-modal .ant-modal-content {
  padding: 0;
}
