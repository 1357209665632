.item {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: start;
  width: 260px;
  height: 40px;
  padding: 0 20px;
  cursor: pointer;
}
.item:hover {
  font-weight: 500;
}
.item_mobile {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  text-align: start;
  width: 260px;
  height: 20px;
}


@media screen and (max-width: 767px){
  .item_mobile {
    margin: 13px 0;
  }

}