.burger__line {
  height: 2px;
  width: 25.0363px;
  background: #000;
  position: absolute;
  left: 0;
  // right: 0;
  border-radius: 10px;

  transition: 0.3s;
  &:first-child {
    top: 2px;
  }
  &:nth-child(2) {
    width: 15.0218px;
    top: 50%;
  }
  &:last-child {
    bottom: 2px;
  }
}
.burger__opened {
  > .burger__line {
    &:first-child {
      top: 50%;
      transform: rotate(-45deg);
    }
    &:nth-child(2) {
      width: 0;
    }
    &:last-child {
      top: 50%;
      transform: rotate(45deg);
    }
  }
}
.burger__nav {
  transition: all 1s;
  transform: translateY(-150%);
}
.burger__nav--opened {
    transition: all 1s;
  transform: translateY(0%);
}

.catalogOpened{
  >svg{
    transform: rotate(90deg);
  }
}