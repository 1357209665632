.bottom_buttons {
  position: relative;
  left: -20px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  padding: 20px;
  background-color: #F9F9F9;
  border: 0.5px solid #D9D9D9;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
}
.nav {
  position: fixed;
  top: 59px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  height: calc(100vh - 59px);
  width: 100vw;
  padding: 20px;
  z-index: 150;
  //transition: all 1s;
  //transform: translateY(-150%);
}
.text {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
  height: 24px;
  margin: 26px;
}

.items {
  display: flex;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.38px;
  height: 24px;
  margin: 13px 0;
}