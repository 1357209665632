.CartIcon:hover path {
    stroke: #F400A1 !important;
    fill: transparent !important;
}
.text {
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
}
.links_text {
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    a {
        text-decoration: underline;
    }
}


.modal {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: 20px 20px 20px 40px;
    border-top: 1px solid #CCCCCC;
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
}
