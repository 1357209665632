.container {
  padding: 4px;
  border-radius: 24px;
  border: 1px solid #3A3A3A;
  display: flex;
  gap: 3px;
  align-items: center;
  align-self: end;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 30px;
  font-size: 26px;
  font-weight: 100;
  color: white;
  background-color: #3A3A3A;
  border: 1px solid #3A3A3A;
  transition: all 0.3s ease;
}
.button:hover {
  background-color: #E2A3CD;
  border: 1px solid #E2A3CD;

}
.inactive_button {
  color: #CCCCCC;
  background-color: transparent;
  border: 1px solid #CCCCCC;
}
.inactive_button:hover {
  color: #CCCCCC;
  background-color: transparent;
  border: 1px solid #CCCCCC;
}