.social__medias {
  > .social-link {
    > svg {
      > path {
        transition: 0.4s;
      }
    }
    &:hover {
      cursor: pointer;
      > svg {
        > path {
          fill: #f5016b;
        }
      }
    }
    &:focus {
      > svg {
        > path {
          fill: #f5016b;
        }
      }
    }
  }
}
