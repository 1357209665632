.button {
  margin: 30px 0;
  button {
    width: 100%;
  }
}
.back {
  position: absolute;
  top: 20px;
  left: 20px;
  svg{
    width: 25px;
  }
}

@keyframes shake {
  0%, 100% { transform: translateY(0); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% { transform: translateY(-1px); }
  10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% { transform: translateY(1px); }
}
.error {
  border-bottom: 1px solid #F400A1;
  animation: shake 1s ease;
  input {
    border: none;
  }

}