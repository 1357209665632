.ProductInCart {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.right_product_cart {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
}
.name {
  transition: all 0.3s ease;
}
.basket {
  opacity: 0;
  transition: all 0.3s ease;
  path {
    transition: all 0.3s ease;
  }
}
.img {
  width: 90px;
  object-fit: cover;
}
.basket:hover {
  path {
    stroke: #F400A1 !important;
  }

}

.ProductInCart:hover {
  .name {
    color: #E2A3CD;
  }
  .basket {
    opacity: 100%;
  }
}

@media screen and (max-width: 767px){
  .basket {
    opacity: 100%;
  }
  .ProductInCart {
    position: relative;
    left: -4px;
    width: calc(100% + 8px);
  }
  .img {
    width: 63px;
    min-width: 63px;
    object-fit: cover;
    flex-shrink: 0;
  }
}